import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import aboutThumb from "../images/artwork-contact.svg";
import { TitleH1 } from "../components/common";
import SEO from "../components/SEO";

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% minmax(400px, 600px);
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 2rem;
  padding: 3.5rem 30px;
  @media (max-width: 1024px) {
    grid-template-columns: 1.2fr 1fr;
    padding: 2rem 30px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 575px) {
    padding: 10px 30px;
    grid-gap: 20px;
  }
`;
const TitleContent = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 1.4rem;
  color: #3d4d69;
`;
const StyledLink = styled(Link)`
  color: #06c;
  text-decoration: none;
`;
const StyledImg = styled.img`
  @media (max-width: 768px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const Contact = () => {
  return (
    <>
      <SEO
        title="Contac Us"
        description="If you have any suggestions or business inquiries, feel free to send a message to us. We are just one message away from you to receive your valuable feedback."
      />
      <Container>
        <div>
          <TitleH1>Contact Us</TitleH1>
          {/* <TitleH2>
            On a mission to provide the best employee management experience.
          </TitleH2>
          <TitleH3>About Timesheet Calculator</TitleH3> */}
          <TitleContent>
            We highly value our users’ feedback and suggestions. If you have any
            queries, suggestions, feedback or business inquiry regarding Appscom
            apps, please send us a message at{" "}
            <StyledLink to="/">support@appscom.io.</StyledLink>
          </TitleContent>
          <a href="mailto:support@appscom.io.com?subject = Feedback&body = Message">
            Send Feedback
          </a>
        </div>
        <StyledImg src={aboutThumb} alt="about us thumbnail" width="100%" />
      </Container>
    </>
  );
};

export default Contact;
